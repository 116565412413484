import { useLeva } from "@/hooks/useLeva";
import { GroupProps } from "@react-three/fiber";
import { FunctionComponent, useCallback } from "react";
import ArrayModifier from "../Utils/ArrayModifier";
import { Handle } from "./Handle";
import { useEnvironment } from "@react-three/drei";
import { useSpring, animated, config } from "@react-spring/three";
import { useAppStore } from "@/stores/appStore";

interface DoorSegmentProps extends GroupProps {
	width: number;
	height: number;
	thickness: number;
	hasHandle?: boolean;
}

const DoorSegment: FunctionComponent<DoorSegmentProps> = ({
	width,
	height,
	thickness,
	hasHandle = false,
	...groupProps
}) => {
	const openDoor = useAppStore((store) => store.openDoor);
	const swingDirection = useAppStore((store) => store.swingDirection);
	const update = useAppStore((store) => store.update);
	const { doorColor, doorReflectivity, doorOpacity, doorRoughness, wireframe } = useLeva();
	const envMap = useEnvironment({ files: ["hdri/thatch_chapel_2k.hdr"] });

	const shift = swingDirection === "right" ? width / 2 : -width / 2;
	const rotation = swingDirection === "right" ? Math.PI / 3 : -Math.PI / 3;
	const springs = useSpring({
		"rotation-y": hasHandle && openDoor ? rotation : 0,
		config: config.slow,
	});

	return (
		<group {...groupProps}>
			<animated.group {...springs} position-x={shift}>
				<mesh position-x={-shift}>
					<boxGeometry args={[width, height, thickness]} />
					<meshPhysicalMaterial
						transparent
						color={doorColor}
						reflectivity={doorReflectivity}
						opacity={doorOpacity}
						roughness={doorRoughness}
						wireframe={wireframe}
						thickness={thickness}
						envMap={envMap}
						envMapIntensity={0.5}
					/>
				</mesh>

				{hasHandle && (
					<Handle
						rotation-y={Math.PI}
						position={[-1.8 * shift, 0, thickness / 2]}
						onClick={() => update({ openDoor: !openDoor })}
					/>
				)}
			</animated.group>
		</group>
	);
};

interface DoorProps extends Omit<GroupProps, "children"> {
	width: number;
	height: number;
	thickness: number;
	segments: number;
}

const Door: FunctionComponent<DoorProps> = ({ width, height, thickness, segments, ...groupProps }) => {
	const segmentWidth = width / segments;
	const extend = useCallback((i: number) => (i === segments - 1 ? { hasHandle: true } : {}), [segments]);

	return (
		<group {...groupProps}>
			<ArrayModifier
				count={segments}
				offset={[segmentWidth, 0, 0]}
				position-x={-(width - segmentWidth) / 2}
				extend={extend}>
				<DoorSegment width={segmentWidth} height={height} thickness={thickness} />
			</ArrayModifier>
		</group>
	);
};

export default Door;
