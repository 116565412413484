import { type FunctionComponent, Suspense } from "react";
import Floor from "./Floor";
import Wall from "./Wall";
import Bench from "./Bench";
import { type GroupProps } from "@react-three/fiber";
import { useLeva } from "@/hooks/useLeva";
import Roof from "./Roof";
import { useAppStore } from "@/stores/appStore";
import { Heater } from "./Heater";
import Door from "./Door";
import { Spot } from "../Lights/Spot";

const Sauna: FunctionComponent<GroupProps> = (props) => {
	const { width, depth, height } = useAppStore((store) => store.saunaDimensions);
	const showWalls = useAppStore((store) => store.showWalls);

	const { showDoor, doorSegments, benchHeight, benchDepth, floorThickness, wallThickness } = useLeva();

	const saunaWidth = width / 100;
	const saunaDepth = depth / 100;
	const saunaHeight = height / 100;

	const roofThickness = floorThickness;
	const wallHeight = saunaHeight - (floorThickness + roofThickness);
	const innerWidth = saunaWidth - 2 * wallThickness;
	const innerDepth = saunaDepth - 2 * wallThickness;

	return (
		<group {...props}>
			{/* Floor */}
			<Floor position={[0, floorThickness / 2, 0]} width={saunaWidth} depth={saunaDepth} thickness={floorThickness} />

			{/* Walls etc. */}
			<group position={[0, floorThickness, 0]}>
				<Heater position={[-(innerWidth - 0.5) / 2, 0, (innerDepth - 0.5) / 2]} />

				{/* Bench */}
				<Suspense fallback={null}>
					<Bench
						width={innerWidth}
						height={benchHeight}
						depth={benchDepth}
						position={[0, benchHeight / 2, (-innerDepth + benchDepth) / 2]}
					/>
				</Suspense>

				{/* Door */}
				{showDoor && showWalls && (
					<Door
						position={[0, wallHeight / 2, (saunaDepth - wallThickness) / 2]}
						width={innerWidth}
						height={wallHeight}
						thickness={wallThickness}
						segments={doorSegments}
					/>
				)}

				{/* Back wall */}
				<Wall
					width={innerWidth}
					height={wallHeight}
					position={[0, wallHeight / 2, -(saunaDepth - wallThickness) / 2]}
					thickness={wallThickness}
				/>

				{/* Left wall */}
				<Wall
					width={saunaDepth}
					height={wallHeight}
					position={[-(saunaWidth - wallThickness) / 2, wallHeight / 2, 0]}
					rotation={[0, Math.PI / 2, 0]}
					thickness={wallThickness}
				/>

				{/* Right wall */}
				<Wall
					width={saunaDepth}
					height={wallHeight}
					position={[(saunaWidth - wallThickness) / 2, wallHeight / 2, 0]}
					rotation={[0, -Math.PI / 2, 0]}
					thickness={wallThickness}
				/>
			</group>

			{/* Roof */}
			<group position={[0, floorThickness + wallHeight, 0]}>
				<Roof position={[0, roofThickness / 2, 0]} width={saunaWidth} depth={saunaDepth} thickness={floorThickness} />

				<Spot position={[-innerWidth / 4, 0, -innerDepth / 4]} />
				<Spot position={[innerWidth / 4, 0, -innerDepth / 4]} />
				<Spot position={[-innerWidth / 4, 0, innerDepth / 4]} />
				<Spot position={[innerWidth / 4, 0, innerDepth / 4]} />
			</group>
		</group>
	);
};

export default Sauna;
