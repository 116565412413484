import { useLeva } from "@/hooks/useLeva";
import { useMemo, type FunctionComponent } from "react";
import { type GroupProps } from "@react-three/fiber";
import Plank from "./Plank";
import ArrayModifier from "../../Utils/ArrayModifier";

/*
 * A board represents a whole sitting area or skirt
 */
interface BoardProps extends Omit<GroupProps, "children"> {
	width: number;
	depth: number;
	count: number;
	spacing: number;
	maxWidth: number;
	dividerWidth: number;
}

const Board: FunctionComponent<BoardProps> = ({
	width,
	depth,
	count,
	spacing,
	maxWidth,
	dividerWidth,
	...groupProps
}) => {
	const { plankThickness, bevelThickness } = useLeva();

	const children = useMemo(() => {
		const N = Math.ceil((width + dividerWidth) / (maxWidth + dividerWidth));
		const remainder = (width - (N - 2) * maxWidth - (N - 1) * dividerWidth) / 2;
		const middle = (N - 1) / 2;
		const plankWidth = depth / count + spacing / count - spacing;

		const calcAvailable = (i: number): number =>
			N > 1 ? Math.min(maxWidth, middle - Math.abs(i - middle) + remainder) : width;

		return Array(N)
			.fill(null)
			.flatMap((_, i) => {
				const spaceAvailable = calcAvailable(i);
				const offset = Array(i)
					.fill(null)
					.reduce<number>((result, _, i) => result + calcAvailable(i) + dividerWidth, -width / 2);

				return [
					<ArrayModifier
						key={i * 2}
						position={[offset + spaceAvailable / 2, 0, (depth - plankWidth) / 2]}
						rotation-y={Math.PI / 2}
						count={count}
						offset={[plankWidth + spacing, 0, 0]}>
						<Plank
							width={plankWidth}
							length={spaceAvailable}
							thickness={plankThickness}
							bevelThickness={bevelThickness}
						/>
					</ArrayModifier>,
					...(i < N - 1 && dividerWidth > 0
						? [
								<Plank
									key={i * 2 + 1}
									width={dividerWidth}
									length={depth}
									thickness={plankThickness}
									bevelThickness={bevelThickness}
									position-x={offset + spaceAvailable + dividerWidth / 2}
								/>,
							]
						: []),
				];
			});
	}, [bevelThickness, count, depth, dividerWidth, maxWidth, plankThickness, spacing, width]);

	return <group {...groupProps}>{children}</group>;
};

export default Board;
