/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.2 heater.glb -t -T 
Files: heater.glb [4.96MB] > /Users/florianwork/Downloads/heater-transformed.glb [1.7MB] (66%)
*/

import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { GroupProps } from "@react-three/fiber";

type GLTFResult = GLTF & {
	nodes: {
		Frame: THREE.Mesh;
		Mesh05: THREE.Mesh;
		Mesh05_1: THREE.Mesh;
		Mesh05_2: THREE.Mesh;
		Mesh05_3: THREE.Mesh;
		Mesh05_4: THREE.Mesh;
		Mesh05_5: THREE.Mesh;
		Mesh05_6: THREE.Mesh;
	};
	materials: {
		_auto_: THREE.MeshStandardMaterial;
		_auto_1: THREE.MeshStandardMaterial;
		_auto_2: THREE.MeshStandardMaterial;
		_auto_3: THREE.MeshStandardMaterial;
		_auto_4: THREE.MeshStandardMaterial;
		_auto_5: THREE.MeshStandardMaterial;
		_auto_6: THREE.MeshStandardMaterial;
		_auto_7: THREE.MeshStandardMaterial;
	};
};

export function Heater(props: GroupProps) {
	const { nodes, materials } = useGLTF("/models/heater-transformed.glb") as GLTFResult;
	return (
		<group {...props} dispose={null}>
			<mesh geometry={nodes.Frame.geometry} material={materials._auto_} />
			<group>
				<mesh geometry={nodes.Mesh05.geometry} material={materials._auto_1} />
				<mesh geometry={nodes.Mesh05_1.geometry} material={materials._auto_2} />
				<mesh geometry={nodes.Mesh05_2.geometry} material={materials._auto_3} />
				<mesh geometry={nodes.Mesh05_3.geometry} material={materials._auto_4} />
				<mesh geometry={nodes.Mesh05_4.geometry} material={materials._auto_5} />
				<mesh geometry={nodes.Mesh05_5.geometry} material={materials._auto_6} />
				<mesh geometry={nodes.Mesh05_6.geometry} material={materials._auto_7} />
			</group>
		</group>
	);
}

useGLTF.preload("/models/heater-transformed.glb");
