import { extend, Object3DNode } from "@react-three/fiber";
import { ExtrudeGeometry, Shape } from "three";

class PlankGeometry extends ExtrudeGeometry {
	constructor(length: number, width: number, thickness: number, bevelThickness = 0) {
		const shape = new Shape();

		const innerWidth = width - bevelThickness * 2;
		const innerHeight = thickness - bevelThickness * 2;

		shape.moveTo(bevelThickness, 0);
		shape.lineTo(innerWidth - bevelThickness, 0);
		shape.lineTo(innerWidth, bevelThickness);
		shape.lineTo(innerWidth, innerHeight - bevelThickness);
		shape.lineTo(innerWidth - bevelThickness, innerHeight);
		shape.lineTo(bevelThickness, innerHeight);
		shape.lineTo(0, innerHeight - bevelThickness);
		shape.lineTo(0, bevelThickness);
		shape.lineTo(bevelThickness, 0);

		const extrudeSettings = {
			steps: 1,
			depth: length - 2 * bevelThickness,
			bevelEnabled: bevelThickness > 0,
			bevelThickness,
			bevelSize: bevelThickness,
			bevelOffset: 0,
			bevelSegments: 1,
		};

		super(shape, extrudeSettings);
		this.center();
	}
}

extend({ PlankGeometry });

declare module "@react-three/fiber" {
	interface ThreeElements {
		plankGeometry: Object3DNode<PlankGeometry, typeof PlankGeometry>;
	}
}
