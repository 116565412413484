import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
	colors: {
		primary: "#082732",
		primary_hover: "#0c3f5a",
		primary_text: "#ffffff",
		secondary: "#306FC4",
		secondary_hover: "#4c8ae9",
		secondary_text: "#ffffff",
		background: "#f6f6f6",
		background_hover: "#eeeeee",
		gray: "#c4c4c4",
	},
	radius: {
		small: "4px",
		medium: "8px",
		large: "12px",
	},
};
