/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.2 handle.glb -t -T 
Files: handle.glb [14.53KB] > /Users/florianwork/Downloads/handle-transformed.glb [2.75KB] (81%)
*/

import * as THREE from "three";
import { useEnvironment, useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { GroupProps } from "@react-three/fiber";
import { useLeva } from "@/hooks/useLeva";
import { useEffect, useState } from "react";

type GLTFResult = GLTF & {
	nodes: {
		Cylinder: THREE.Mesh;
	};
	materials: {
		["Material.001"]: THREE.MeshStandardMaterial;
	};
};

export function Handle(props: GroupProps) {
	const { nodes, materials } = useGLTF("/models/handle-transformed.glb") as GLTFResult;
	const { wireframe } = useLeva();
	const envMap = useEnvironment({ files: ["hdri/thatch_chapel_2k.hdr"] });
	materials["Material.001"].wireframe = wireframe;
	materials["Material.001"].envMap = envMap;

	const [hovered, setHovered] = useState(false);

	useEffect(() => {
		document.body.style.cursor = hovered ? "pointer" : "auto";
		return () => void (document.body.style.cursor = "auto");
	}, [hovered]);

	return (
		<group {...props} dispose={null} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
			<mesh geometry={nodes.Cylinder.geometry} material={materials["Material.001"]} />
		</group>
	);
}

useGLTF.preload("/models/handle-transformed.glb");
