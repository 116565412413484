import { type State, useAppStore } from "@/stores/appStore";
import { type FunctionComponent } from "react";
import styled from "styled-components";
import ImageButton from "./ImageButton";
import { TextureOption } from "@/constants";

interface MaterialSelectProps {
	label: string;
	target: keyof State; // TODO: make this an enum
	options: TextureOption[];
}

const OptionsContainer = styled.div<{ $max: number }>`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(4rem, 1fr));
	gap: 1rem;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const MaterialSelect: FunctionComponent<MaterialSelectProps> = ({ label, target, options }) => {
	const update = useAppStore((store) => store.update);
	const selectedOption = useAppStore((store) => store[target]);

	return (
		<Container>
			<p>{label}</p>
			<OptionsContainer $max={5}>
				{options.map((option) => (
					<ImageButton
						key={option}
						option={option}
						className="option"
						onClick={() => update({ [target]: option })}
						isSelected={selectedOption === option}
					/>
				))}
			</OptionsContainer>
		</Container>
	);
};

export default MaterialSelect;
