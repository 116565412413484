import Navigation from "@/components/ui/Sidepanel/Navigation";
import Section from "@/components/ui/Sidepanel/Section";
import { useAppStore } from "@/stores/appStore";
import { createFileRoute } from "@tanstack/react-router";
import { FunctionComponent } from "react";
import styled from "styled-components";

export const Route = createFileRoute("/_configurator/step-4")({
	component: Step4,
});

interface ItemProps {
	name: string;
	value: string;
}

const StyledItem = styled.div`
	display: inline-flex;
	flex-direction: row;
	width: 100%;

	.label {
		width: 50%;
	}

	.label:after {
		content: ":";
	}
`;

const Item: FunctionComponent<ItemProps> = ({ name, value }) => (
	<StyledItem>
		<p className="label">{name}</p>
		<p>{value}</p>
	</StyledItem>
);

function Step4() {
	const innerTexture = useAppStore((store) => store.innerTexture);
	const floorTexture = useAppStore((store) => store.floorTexture);
	const benchTexture = useAppStore((store) => store.benchTexture);
	const benchLevels = useAppStore((store) => store.benchLevels);
	const swingDirection = useAppStore((store) => store.swingDirection);

	return (
		<>
			<Section title="Summary">
				<Item name="Wall material" value={innerTexture} />
				<Item name="Floor material" value={floorTexture} />
				<Item name="Bench material" value={benchTexture} />
				<Item name="Bench levels" value={benchLevels} />
				<Item name="Door handing" value={swingDirection === "left" ? "Opens left" : "Opens right"} />
			</Section>
			<Navigation label="Summary" backward="/step-3" />
		</>
	);
}
