/* eslint-disable @typescript-eslint/no-unused-vars */
import { QueryClient } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
// import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { Leva } from "leva";

const DISPLAY_DEVTOOLS = import.meta.env.DEV;

export const Route = createRootRouteWithContext<{
	queryClient: QueryClient;
}>()({
	component: () => (
		<>
			<Outlet />
			<Leva hidden={!DISPLAY_DEVTOOLS} theme={{ sizes: { rootWidth: "350px" } }} />
			{/* {DISPLAY_DEVTOOLS && <ReactQueryDevtools buttonPosition="bottom-left" />}
			{DISPLAY_DEVTOOLS && <TanStackRouterDevtools position="bottom-right" />} */}
		</>
	),
});
