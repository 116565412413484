import { setRepeatTextures, useDeferredTexture } from "@/hooks/useDeferredTexture";
import { useLeva } from "@/hooks/useLeva";
import { useAppStore } from "@/stores/appStore";
import { MeshProps } from "@react-three/fiber";
import { FunctionComponent, useMemo } from "react";
import { MeshStandardMaterial } from "three";

interface RoofProps extends MeshProps {
	width: number;
	depth: number;
	thickness?: number;
}

const defaultMaterial = new MeshStandardMaterial({ color: "grey" });
const transparentMaterial = new MeshStandardMaterial({ transparent: true, opacity: 0, alphaTest: 1 });

const Roof: FunctionComponent<RoofProps> = ({ width, depth, thickness = 0.05, ...meshProps }) => {
	const showWalls = useAppStore((store) => store.showWalls);
	const innerTexture = useAppStore((store) => store.innerTexture);
	const { wireframe, wallTextureRoughness: roughness } = useLeva();
	const textures = useDeferredTexture(innerTexture);
	const textureMaterial = useMemo(() => new MeshStandardMaterial(), []);

	textureMaterial.wireframe = wireframe;
	textureMaterial.roughness = roughness;
	textureMaterial.map = textures.map;
	textureMaterial.normalMap = textures.normalMap ?? null;

	defaultMaterial.wireframe = wireframe;
	transparentMaterial.wireframe = wireframe;
	setRepeatTextures(textures, innerTexture, width, depth);

	const materials = useMemo(
		() => [
			showWalls ? defaultMaterial : transparentMaterial,
			showWalls ? defaultMaterial : transparentMaterial,
			showWalls ? textureMaterial : transparentMaterial,
			textureMaterial,
			showWalls ? defaultMaterial : transparentMaterial,
			showWalls ? defaultMaterial : transparentMaterial,
		],
		[showWalls, textureMaterial]
	);

	return (
		<mesh {...meshProps}>
			<boxGeometry args={[width, thickness, depth]} />
			<primitive object={materials} attach="material" />
		</mesh>
	);
};

export default Roof;
