/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.2 light_fixture_-_ceiling_recessed/scene.gltf -t -T 
Files: light_fixture_-_ceiling_recessed/scene.gltf [5.16KB] > /Users/florianwork/Downloads/scene-transformed.glb [27.24KB] (-428%)
Author: MozillaHubs (https://sketchfab.com/mozillareality)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/light-fixture-ceiling-recessed-269fd427629548a8a0949a6493c5b223
Title: Light Fixture - Ceiling Recessed
*/

import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { GroupProps } from "@react-three/fiber";
import { useLeva } from "@/hooks/useLeva";

type GLTFResult = GLTF & {
	nodes: {
		Cylinder__0: THREE.Mesh;
	};
	materials: {
		["Scene_-_Root"]: THREE.MeshStandardMaterial;
	};
};

export function Spot(props: GroupProps) {
	const { nodes, materials } = useGLTF("/models/spot-transformed.glb") as GLTFResult;
	const { wireframe, lightColor, pointLightIntensity } = useLeva();
	materials["Scene_-_Root"].wireframe = wireframe;

	return (
		<group {...props} dispose={null}>
			<pointLight color={lightColor} intensity={pointLightIntensity} position-y={-0.1} />
			<mesh
				geometry={nodes.Cylinder__0.geometry}
				material={materials["Scene_-_Root"]}
				rotation={[-Math.PI / 2, 0, 0]}
			/>
		</group>
	);
}

useGLTF.preload("/models/spot-transformed.glb");
