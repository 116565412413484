import { useLeva } from "@/hooks/useLeva";
import { Environment } from "@react-three/drei";

export default function Lights() {
	const { ambientIntensity, lightColor, environmentIntensity } = useLeva();

	return (
		<>
			<ambientLight color={lightColor} intensity={ambientIntensity} />
			<Environment files={["/hdri/env.exr"]} environmentIntensity={environmentIntensity} />;
		</>
	);
}
