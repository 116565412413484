import { useLeva } from "@/hooks/useLeva";
import { type FunctionComponent } from "react";
import { type GroupProps } from "@react-three/fiber";
import Board from "./Board";
import ArrayModifier from "../../Utils/ArrayModifier";
import BackRest from "./BackRest";
import { useAppStore } from "@/stores/appStore";

interface BenchProps extends Omit<GroupProps, "args"> {
	width: number;
	height: number;
	depth: number;
}

const Bench: FunctionComponent<BenchProps> = ({ width, height, depth, ...groupProps }) => {
	const benchLevels = useAppStore((store) => store.benchLevels);
	const {
		dividerWidth,
		seatMaxWidth,
		seatHorizontalCount,
		seatVerticalCount,
		seatHorizontalSpacing,
		seatVerticalSpacing,
		backRestHeight,
		backRestOffset,
		plankThickness,
		showSkirt,
	} = useLeva();

	return (
		<group {...groupProps}>
			<ArrayModifier
				position-y={(benchLevels - 1) * height}
				count={benchLevels}
				offset={[0, -height, depth - plankThickness]}>
				<group>
					{/* Seat */}
					<Board
						width={width}
						depth={depth}
						count={seatHorizontalCount}
						spacing={seatHorizontalSpacing}
						maxWidth={seatMaxWidth}
						dividerWidth={dividerWidth}
						position-y={(height - plankThickness) / 2}
					/>

					{/* Skirt */}
					{showSkirt && (
						<Board
							width={width}
							depth={height - plankThickness}
							count={seatVerticalCount}
							spacing={seatVerticalSpacing}
							maxWidth={seatMaxWidth}
							dividerWidth={dividerWidth}
							position={[0, -plankThickness / 2, (depth - plankThickness) / 2]}
							rotation-x={Math.PI / 2}
						/>
					)}
				</group>
			</ArrayModifier>
			<BackRest
				width={width}
				height={backRestHeight}
				position={[0, (benchLevels - 0.5) * height + backRestHeight / 2 + backRestOffset, -depth / 2]}
			/>
		</group>
	);
};

export default Bench;
