import { createFileRoute, Outlet } from "@tanstack/react-router";
import styled from "styled-components";
import Scene from "@/components/three/Scene";
import Sidepanel from "@/components/ui/Sidepanel";
import Overlay from "@/components/ui/Overlay";

export const Route = createFileRoute("/_configurator")({
	component: Configurator,
});

function Configurator() {
	return (
		<ConfiguratorContainer>
			<Scene />
			<Overlay />
			<Sidepanel>
				<Outlet />
			</Sidepanel>
		</ConfiguratorContainer>
	);
}

const ConfiguratorContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100svh;
`;
