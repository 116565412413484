import { ReactNode } from "react";
import styled from "styled-components";

type SidepanelProps = {
	children: ReactNode;
};

export default function Sidepanel({ children }: SidepanelProps) {
	return (
		<SidepanelContainer>
			<SidepanelContent>{children}</SidepanelContent>
		</SidepanelContainer>
	);
}

const SidepanelContainer = styled.div`
	box-shadow: 0 0 1rem rgb(from var(--grey-rock) r g b / 0.2);
	width: min(100%, 450px);
	display: flex;
	background-color: var(--coconut-white);
	user-select: none;
`;

const SidepanelContent = styled.div`
	width: 100%;
	height: 100%;
	padding: 2rem;

	display: flex;
	flex-direction: column;
	gap: 3rem;

	& > *:last-child {
		margin-top: auto;
	}
`;
