import { type FunctionComponent } from "react";
import { useLeva } from "@/hooks/useLeva";
import { type GroupProps } from "@react-three/fiber";
import Plank from "./Plank";
import ArrayModifier from "../../Utils/ArrayModifier";

interface BackRestProps extends Omit<GroupProps, "args"> {
	width: number;
	height: number;
}

const BackRest: FunctionComponent<BackRestProps> = ({ width, height, ...groupProps }) => {
	const {
		plankThickness,
		backRestCount,
		backRestSpacing,
		backRestSpacerWidth,
		backRestSpacerOffset,
		backRestColor,
		backRestIntensity,
		bevelThickness,
	} = useLeva();
	const plankWidth = height / backRestCount + backRestSpacing / backRestCount - backRestSpacing;

	return (
		<group {...groupProps}>
			<rectAreaLight
				args={[backRestColor, backRestIntensity, width - 2 * (backRestSpacerOffset + plankThickness), height]}
				position-z={backRestSpacerWidth - 0.0001}
			/>
			<Plank
				width={backRestSpacerWidth}
				length={height}
				thickness={plankThickness}
				position-x={-width / 2 + backRestSpacerOffset}
				position-z={backRestSpacerWidth / 2}
				rotation={[Math.PI / 2, 0, Math.PI / 2]}
				bevelThickness={bevelThickness}
			/>
			<Plank
				width={backRestSpacerWidth}
				length={height}
				thickness={plankThickness}
				position-x={width / 2 - backRestSpacerOffset}
				position-z={backRestSpacerWidth / 2}
				rotation={[Math.PI / 2, 0, Math.PI / 2]}
				bevelThickness={bevelThickness}
			/>

			<ArrayModifier
				count={backRestCount}
				offset={[plankWidth + backRestSpacing, 0, 0]}
				position={[0, -(height - plankWidth) / 2, plankThickness / 2 + backRestSpacerWidth]}
				rotation={[0, Math.PI / 2, Math.PI / 2]}>
				<Plank length={width} width={plankWidth} thickness={plankThickness} bevelThickness={bevelThickness} />
			</ArrayModifier>
		</group>
	);
};

export default BackRest;
