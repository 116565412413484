import { State, useAppStore } from "@/stores/appStore";
import styled from "styled-components";

const StyledSelect = styled.select`
	border-radius: 0.5rem;
	padding: 0.5rem 1rem;
	font-family: var(--base-sans);
`;
type FilterConditionally<Source, Condition> = Pick<
	Source,
	{ [K in keyof Source]: Source[K] extends Condition ? K : never }[keyof Source]
>;

const DropDown = <K extends keyof FilterConditionally<State, string>>({
	name,
	options,
}: {
	name: K;
	options: State[K][];
}) => {
	const update = useAppStore((store) => store.update);
	const value = useAppStore((store) => store[name]);

	return (
		<StyledSelect value={value} onChange={(e) => update({ [name]: e.target.value })}>
			{options.map((option) => (
				<option key={option} value={option}>
					{option.charAt(0).toUpperCase() + option.slice(1)}
				</option>
			))}
		</StyledSelect>
	);
};

export default DropDown;
