import CONSTANTS, { TextureOption } from "@/constants";
import { useTexture } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useDeferredValue } from "react";
import { LinearSRGBColorSpace, RepeatWrapping, SRGBColorSpace } from "three";

export type TextureMap = {
	map: string;
	normalMap?: string;
	roughnessMap?: string;
	rotation?: number;
};

type TexturesType = ReturnType<typeof useTexture<Omit<TextureMap, "rotation">>>;

const setRepeatTextures = (textures: TexturesType, textureName: TextureOption, s: number, t: number) => {
	for (const texture of Object.values(textures)) {
		texture.wrapS = texture.wrapT = RepeatWrapping;
		texture.repeat.set(
			s / (CONSTANTS.TEXTURES[textureName].width ?? 1),
			t / (CONSTANTS.TEXTURES[textureName].height ?? 1)
		);
	}
};

const useDeferredTexture = (textureName: TextureOption) => {
	// Some variables.
	const gl = useThree((state) => state.gl);
	const anisotropy = gl.capabilities.getMaxAnisotropy();
	const { rotation = 0, ...maps } = CONSTANTS.TEXTURES[textureName];

	// Get textures.
	const map = useDeferredValue(maps.map);
	const normalMap = useDeferredValue(maps.normalMap);
	const roughnessMap = useDeferredValue(maps.roughnessMap);
	const deferredTextures = { map, ...(normalMap ? { normalMap } : {}), ...(roughnessMap ? { roughnessMap } : {}) };
	const textures = useTexture(deferredTextures);

	// Initialize.
	for (const [name, texture] of Object.entries(textures)) {
		texture.flipY = false;
		texture.anisotropy = anisotropy;
		texture.rotation = rotation;
		texture.colorSpace = name === "map" ? SRGBColorSpace : LinearSRGBColorSpace;
	}

	return textures;
};

export { type TexturesType, setRepeatTextures, useDeferredTexture };
