import NumberInput from "@/components/primitives/NumberInput";
import MaterialSelect from "@/components/ui/MaterialSelect";
import Navigation from "@/components/ui/Sidepanel/Navigation";
import Section from "@/components/ui/Sidepanel/Section";
import { TextureOption } from "@/constants";
import { useAppStore } from "@/stores/appStore";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_configurator/step-3")({
	component: Step3,
});

function Step3() {
	const benchLevels = useAppStore((store) => store.benchLevels);
	const update = useAppStore((store) => store.update);

	return (
		<>
			<Section title="Materials">
				<MaterialSelect
					label="Benches"
					target="benchTexture"
					options={[TextureOption.CEDAR, TextureOption.FIR, TextureOption.PINE]}
				/>
			</Section>
			<Section title="Layout">
				<NumberInput
					min={1}
					max={2}
					label="Levels"
					value={benchLevels}
					onChange={(benchLevels) => update({ benchLevels })}
				/>
			</Section>
			<Navigation label="Benches" backward="/step-2" forward="/step-4" />
		</>
	);
}
