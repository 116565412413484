import CONSTANTS, { TextureOption } from "@/constants";
import { ComponentProps, type FunctionComponent } from "react";
import styled from "styled-components";

const StyledButton = styled.button<{ $isSelected: boolean }>`
	border: none;
	border-radius: 1rem;
	overflow: hidden;
	outline: ${(props) => (props.$isSelected ? "solid 2px var(--grey-rock)" : "none")};

	img {
		aspect-ratio: 1;
		width: 100%;
		height: 100%;
		object-fit: fill;
		object-position: left top;
	}

	&:hover {
		cursor: pointer;
	}
`;

interface ImageButtonProps extends Omit<ComponentProps<typeof StyledButton>, "$isSelected"> {
	isSelected: boolean;
	option: TextureOption;
	onClick: () => void;
}

const ImageButton: FunctionComponent<ImageButtonProps> = ({ option, onClick, isSelected, ...buttonProps }) => {
	return (
		<StyledButton title={option} onClick={onClick} $isSelected={isSelected} {...buttonProps}>
			<img src={CONSTANTS.TEXTURES[option].map} alt={option} />
		</StyledButton>
	);
};

export default ImageButton;
