const TEXTURE_FOLDER = "/textures";

export enum TextureOption {
	DEBUG = "Debug",
	CEDAR = "Cedar",
	PINE = "Pine",
	FIR = "Fir",
	CEDAR_STACK = "Stacked Cedar",
	PINE_STACK = "Stacked Pine",
	FIR_STACK = "Stacked Fir",
	WHITE_MARBLE = "White Marble",
	LIMESTONE = "Limestone",
	TERRAZZO = "Terrazzo",
	LARVIKITE = "Larvikite",
	BASALT = "Basalt",
}

type TextureData = {
	map: string;
	normalMap?: string;
	roughnessMap?: string;
	rotation?: number;
	width?: number;
	height?: number;
};

const TEXTURES: Record<TextureOption, TextureData> = Object.freeze({
	[TextureOption.DEBUG]: { map: `${TEXTURE_FOLDER}/debug.png` },
	[TextureOption.CEDAR]: {
		map: `${TEXTURE_FOLDER}/Western Red Cedar None 3230 x 2285 mm texture.jpg`,
		normalMap: `${TEXTURE_FOLDER}/Western Red Cedar None 3230 x 2285 mm normal.jpg`,
		width: 3.23,
		height: 2.285,
		rotation: Math.PI / 2,
	},
	[TextureOption.PINE]: {
		map: `${TEXTURE_FOLDER}/Pine None 3920 x 2772 mm texture.jpg`,
		normalMap: `${TEXTURE_FOLDER}/Pine None 3920 x 2772 mm normal.jpg`,
		width: 3.92,
		height: 2.772,
		rotation: Math.PI / 2,
	},
	[TextureOption.FIR]: {
		map: `${TEXTURE_FOLDER}/Douglas Fir None 3100 x 2193 mm texture.jpg`,
		normalMap: `${TEXTURE_FOLDER}/Douglas Fir None 3100 x 2193 mm normal.jpg`,
		width: 3.1,
		height: 2.193,
		rotation: Math.PI / 2,
	},
	[TextureOption.CEDAR_STACK]: {
		map: `${TEXTURE_FOLDER}/Western Red Cedar Stack 2310 x 2305 mm texture.jpg`,
		normalMap: `${TEXTURE_FOLDER}/Western Red Cedar Stack 2310 x 2305 mm normal.jpg`,
		width: 2.31,
		height: 2.305,
	},
	[TextureOption.PINE_STACK]: {
		map: `${TEXTURE_FOLDER}/Pine Stack 2310 x 2305 mm texture.jpg`,
		normalMap: `${TEXTURE_FOLDER}/Pine Stack 2310 x 2305 mm normal.jpg`,
		width: 2.31,
		height: 2.305,
	},
	[TextureOption.FIR_STACK]: {
		map: `${TEXTURE_FOLDER}/Douglas Fir Stack 2310 x 2305 mm texture.jpg`,
		normalMap: `${TEXTURE_FOLDER}/Douglas Fir Stack 2310 x 2305 mm normal.jpg`,
		width: 2.31,
		height: 2.305,
	},
	[TextureOption.WHITE_MARBLE]: {
		map: `${TEXTURE_FOLDER}/White Marble Stack 1208 x 1208 mm texture.jpg`,
		normalMap: `${TEXTURE_FOLDER}/White Marble Stack 1208 x 1208 mm normal.jpg`,
		roughnessMap: `${TEXTURE_FOLDER}/White Marble Stack 1208 x 1208 mm roughness.jpg`,
		width: 1.208,
		height: 1.208,
	},
	[TextureOption.LIMESTONE]: {
		map: `${TEXTURE_FOLDER}/Limestone Stack 3018 x 3060 mm texture.jpg`,
		normalMap: `${TEXTURE_FOLDER}/Limestone Stack 3018 x 3060 mm normal.jpg`,
		roughnessMap: `${TEXTURE_FOLDER}/Limestone Stack 3018 x 3060 mm roughness.jpg`,
		width: 3.018,
		height: 3.06,
	},
	[TextureOption.TERRAZZO]: {
		map: `${TEXTURE_FOLDER}/Sabbia Terrazzo Stack 1208 x 1208 mm texture.jpg`,
		normalMap: `${TEXTURE_FOLDER}/Sabbia Terrazzo Stack 1208 x 1208 mm normal.jpg`,
		roughnessMap: `${TEXTURE_FOLDER}/Sabbia Terrazzo Stack 1208 x 1208 mm roughness.jpg`,
		width: 1.208,
		height: 1.208,
	},
	[TextureOption.LARVIKITE]: {
		map: `${TEXTURE_FOLDER}/Larvikite Stack 1208 x 1208 mm texture.jpg`,
		normalMap: `${TEXTURE_FOLDER}/Larvikite Stack 1208 x 1208 mm normal.jpg`,
		roughnessMap: `${TEXTURE_FOLDER}/Larvikite Stack 1208 x 1208 mm roughness.jpg`,
		width: 1.208,
		height: 1.208,
	},
	[TextureOption.BASALT]: {
		map: `${TEXTURE_FOLDER}/Basalt Stack 2525 x 2525 mm texture.jpg`,
		normalMap: `${TEXTURE_FOLDER}/Basalt Stack 2525 x 2525 mm normal.jpg`,
		roughnessMap: `${TEXTURE_FOLDER}/Basalt Stack 2525 x 2525 mm roughness.jpg`,
		width: 2.525,
		height: 2.525,
	},
});

const SAUNA = Object.freeze({
	DEFAULT_WIDTH: 250,
	MIN_WIDTH: 150,
	MAX_WIDTH: 450,
	WIDTH_STEP_SIZE: 5,

	DEFAULT_DEPTH: 200,
	MIN_DEPTH: 150,
	MAX_DEPTH: 350,
	DEPTH_STEP_SIZE: 5,

	DEFAULT_HEIGHT: 210,
	MIN_HEIGHT: 200,
	MAX_HEIGHT: 230,
	HEIGHT_STEP_SIZE: 5,

	DEFAULT_FLOOR_TEXTURE: TextureOption.WHITE_MARBLE,
	DEFAULT_WALL_TEXTURE: TextureOption.FIR_STACK,
	DEFAULT_BENCH_TEXTURE: TextureOption.FIR,
});

const CONSTANTS = Object.freeze({
	SAUNA,
	TEXTURES,
});

export default CONSTANTS;
