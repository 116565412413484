import { Canvas } from "@react-three/fiber";
import Performance from "./Performance";
import Lights from "./Lights";
import Controls from "./Controls";
import Effects from "./Effects";
import Product from "./Product";

export default function Scene() {
	return (
		<Canvas dpr={1.5} shadows camera={{ fov: 40, position: [0, 1, 6] }} style={{ width: "100%", height: "100svh" }}>
			<Performance>
				<Controls />
				<Lights />
				<Effects />
				<Product position={[0, -1, 0]} />
			</Performance>
		</Canvas>
	);
}
