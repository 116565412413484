import { useRef, type FunctionComponent } from "react";
import { type MeshProps } from "@react-three/fiber";
import { useLeva } from "@/hooks/useLeva";
import { setRepeatTextures, useDeferredTexture } from "@/hooks/useDeferredTexture";
import { Color, Vector2 } from "three";
import { useAppStore } from "@/stores/appStore";
import "../../Utils/PlankGeometry";

interface PlankProps extends Omit<MeshProps, "args"> {
	width: number;
	length: number;
	thickness: number;
	bevelThickness?: number;
}

const Plank: FunctionComponent<PlankProps> = ({ width, length, thickness, bevelThickness = 0, ...meshProps }) => {
	const benchTexture = useAppStore((store) => store.benchTexture);
	const {
		wireframe,
		benchTextureRoughness: roughness,
		benchTextureRepeat: repeat,
		benchTextureNormalScale: normalScale,
		benchTextureRandomness,
	} = useLeva();

	const textures = useDeferredTexture(benchTexture);
	setRepeatTextures(textures, benchTexture, repeat, repeat);

	const random = useRef(Math.random()).current;
	const factor = 1 + (random - 0.5) * benchTextureRandomness;

	return (
		<mesh {...meshProps}>
			<plankGeometry args={[length, width, thickness, bevelThickness]} />
			<meshStandardMaterial
				metalness={0}
				roughness={roughness}
				wireframe={wireframe}
				color={new Color(factor, factor, factor)}
				normalScale={new Vector2(...normalScale)}
				{...textures}
			/>
		</mesh>
	);
};

export default Plank;
