import { type Vector3, type GroupProps } from "@react-three/fiber";
import { Children, cloneElement, type ReactElement, useMemo } from "react";

type WithPosition = { position: Vector3 };

interface ArrayModifierProps<T extends WithPosition> extends Omit<GroupProps, "children"> {
	count: number;
	offset: [number, number, number];
	extend?: (i: number, originalProps?: T) => Partial<T>;
	children: ReactElement<T>;
}

const ArrayModifier = <T extends WithPosition = WithPosition>({
	count,
	offset,
	extend,
	children,
	...groupProps
}: ArrayModifierProps<T>) => {
	// Guards if children is undefined or multiple.
	const original = Children.only(children);

	const childArray = useMemo(
		() =>
			Array.from(Array(count), (_, i) =>
				cloneElement(original, {
					key: i,
					...original.props, // FIXME: types
					position: offset.map((v) => v * i) as [number, number, number],
					...extend?.(i, original.props),
				})
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[count, original, JSON.stringify(offset)]
	);

	return <group {...groupProps}>{childArray}</group>;
};

export default ArrayModifier;
