import { Link } from "@tanstack/react-router";
import { ComponentProps, FunctionComponent } from "react";
import styled from "styled-components";

interface ArrowProps {
	direction: "left" | "right";
}

const Arrow: FunctionComponent<ArrowProps> = ({ direction }) => {
	const leftPath = (
		<path
			fill="currentColor"
			d="m11.028 18.677-9.239-8.47h17.534v-1H1.79L11.028.738l-.675-.739-10.146 9.3a.528.528 0 0 0-.087.08.5.5 0 0 0 0 .64.516.516 0 0 0 .087.08l10.146 9.315Z"
		/>
	);

	const rightPath = (
		<path
			fill="currentColor"
			d="m8.294 18.677 9.239-8.47H0v-1h17.533L8.295.738l.675-.739 10.146 9.3a.528.528 0 0 1 .087.08.5.5 0 0 1 .116.32.5.5 0 0 1-.116.32.516.516 0 0 1-.087.08l-10.146 9.3Z"
		/>
	);

	return (
		<span>
			<svg viewBox="0 0 19.323 19.414">{direction === "left" ? leftPath : rightPath}</svg>
		</span>
	);
};

const ArrowLink: FunctionComponent<ArrowProps & ComponentProps<typeof Link>> = ({ direction, ...linkProps }) => (
	<Link {...linkProps}>
		<Arrow direction={direction} />
	</Link>
);

const StyledArrowLink = styled(ArrowLink)`
	width: 2.5rem;
	height: 2.5rem;
	padding: 0.5em;

	border: 1px solid var(--grey-rock);
	border-radius: 100%;

	&[aria-disabled="true"] {
		color: var(--chinese-mint);
		border-color: var(--chinese-mint);
	}

	&:link,
	&:visited {
		color: var(--grey-rock);
	}
`;

interface NavigationProps {
	label: string;
	forward?: ComponentProps<typeof Link>["to"];
	backward?: ComponentProps<typeof Link>["to"];
}

const NavigationContainer = styled.div`
	padding: 1rem;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	p {
		flex-grow: 1;
		text-align: center;
		font-family: var(--base-serif);
		font-size: 1.5em;
	}
`;

// TODO: Use TanStack context
const Navigation: FunctionComponent<NavigationProps> = ({ label, backward, forward }) => {
	return (
		<NavigationContainer>
			<StyledArrowLink to={backward} disabled={!backward} direction="left" />
			<p>{label}</p>
			<StyledArrowLink to={forward} disabled={!forward} direction="right" />
		</NavigationContainer>
	);
};

export default Navigation;
