import { MeshProps, MeshStandardMaterialProps } from "@react-three/fiber";
import { FunctionComponent, useMemo } from "react";
import { useLeva } from "@/hooks/useLeva";
import { setRepeatTextures, useDeferredTexture } from "@/hooks/useDeferredTexture";
import { useAppStore } from "@/stores/appStore";

interface WallProps extends MeshProps {
	width: number;
	height: number;
	thickness?: number;
}

const Wall: FunctionComponent<WallProps> = ({ width, height, thickness, ...meshProps }) => {
	const wallTexture = useAppStore((store) => store.innerTexture);

	const {
		wireframe,
		wallTextureRoughness: roughness,
		wallTextureNormalScale: _normalScale,
		wallTextureRepeat: repeat,
	} = useLeva();
	const showWalls = useAppStore((store) => store.showWalls);
	const textures = useDeferredTexture(wallTexture);

	const sharedProps = {
		wireframe,
		roughness,
	} satisfies MeshStandardMaterialProps;

	const defaultProps = {
		visible: showWalls,
		color: "grey",
		...sharedProps,
	};

	const clonedTextures = useMemo(
		() => Object.fromEntries(Object.entries(textures).map(([key, texture]) => [key, texture.clone()])),
		[textures]
	) as typeof textures;

	setRepeatTextures(clonedTextures, wallTexture, width * repeat, height * repeat);

	const textureProps = {
		...sharedProps,
		...clonedTextures,
	} satisfies MeshStandardMaterialProps;

	return (
		<mesh {...meshProps}>
			<boxGeometry args={[width, height, thickness]} />
			<meshStandardMaterial attach="material-0" {...defaultProps} />
			<meshStandardMaterial attach="material-1" {...defaultProps} />
			<meshStandardMaterial attach="material-2" {...defaultProps} />
			<meshStandardMaterial attach="material-3" {...defaultProps} />
			<meshStandardMaterial attach="material-4" {...textureProps} />
			<meshStandardMaterial visible={showWalls} attach="material-5" {...textureProps} />
		</mesh>
	);
};

export default Wall;
