import NumberInput from "@/components/primitives/NumberInput";
import MaterialSelect from "@/components/ui/MaterialSelect";
import Navigation from "@/components/ui/Sidepanel/Navigation";
import Section from "@/components/ui/Sidepanel/Section";
import CONSTANTS, { TextureOption } from "@/constants";
import { useAppStore } from "@/stores/appStore";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_configurator/step-1")({
	component: Step1,
});

function Step1() {
	const { saunaDimensions, updateSauna } = useAppStore();

	return (
		<>
			<Section title="Dimensions">
				<NumberInput
					label="Width"
					value={saunaDimensions.width}
					min={CONSTANTS.SAUNA.MIN_WIDTH}
					max={CONSTANTS.SAUNA.MAX_WIDTH}
					step={CONSTANTS.SAUNA.WIDTH_STEP_SIZE}
					onChange={(width) => updateSauna({ width })}
				/>
				<NumberInput
					label="Depth"
					value={saunaDimensions.depth}
					min={CONSTANTS.SAUNA.MIN_DEPTH}
					max={CONSTANTS.SAUNA.MAX_DEPTH}
					step={CONSTANTS.SAUNA.DEPTH_STEP_SIZE}
					onChange={(depth) => updateSauna({ depth })}
				/>
			</Section>

			<Section title="Materials">
				<MaterialSelect
					label="Walls"
					target="innerTexture"
					options={[TextureOption.CEDAR_STACK, TextureOption.FIR_STACK, TextureOption.PINE_STACK]}
				/>
				<MaterialSelect
					label="Floor"
					target="floorTexture"
					options={[
						TextureOption.WHITE_MARBLE,
						TextureOption.LIMESTONE,
						TextureOption.TERRAZZO,
						TextureOption.LARVIKITE,
						TextureOption.CEDAR_STACK,
						TextureOption.FIR_STACK,
						TextureOption.PINE_STACK,
					]}
				/>
			</Section>

			<Navigation label="Walls" forward="/step-2" />
		</>
	);
}
