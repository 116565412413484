import { type ChangeEventHandler, type FunctionComponent, useState } from "react";
import styled from "styled-components";

interface NumberInputProps {
	label: string;
	value: number;
	min: number;
	max: number;
	step?: number;
	onChange: (value: number) => void;
}

const Label = styled.label`
	font-weight: bold;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const Input = styled.input.attrs<{ $isValid: boolean }>({ type: "number" })`
	border-radius: 0.5rem;
	padding: 0.5rem 1rem;
	border: ${(props) => (props.$isValid ? "none" : "1px solid red")};
`;

const NumberInput: FunctionComponent<NumberInputProps> = ({ label, value, min, max, step, onChange }) => {
	const [isValid, setIsValid] = useState(true);
	const [inputValue, setInputValue] = useState(value);

	const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
		const value = parseInt(event.target.value);

		if (value >= min && value <= max) {
			setIsValid(true);
			onChange(value);
		} else {
			setIsValid(false);
		}

		setInputValue(value);
	};

	return (
		<Label>
			{label}
			<Input
				type="number"
				$isValid={isValid}
				id={label}
				value={inputValue}
				min={min}
				max={max}
				step={step}
				onChange={handleInputChange}
			/>
		</Label>
	);
};

export default NumberInput;
