import { type ReactNode, type FunctionComponent } from "react";
import styled from "styled-components";

interface SectionProps {
	title: string;
	children: ReactNode;
	className?: string;
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
`;

const StyledSection = styled.section`
	display: flex;
	flex-direction: column;
	font-size: 1.25rem;
	gap: 2rem;

	h1 {
		font-family: var(--base-serif);
		font-size: 2rem;
		border-bottom: 1px solid var(--grey-rock);
	}
`;

const Section: FunctionComponent<SectionProps> = ({ title, children, className }) => {
	return (
		<StyledSection className={className}>
			<h1>{title}</h1>
			<Container>{children}</Container>
		</StyledSection>
	);
};

export default Section;
