import DropDown from "@/components/primitives/OptionDropDown";
import Navigation from "@/components/ui/Sidepanel/Navigation";
import Section from "@/components/ui/Sidepanel/Section";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_configurator/step-2")({
	component: Step2,
});

function Step2() {
	return (
		<>
			<Section title="Handing">
				<label>Swing direction</label>
				<DropDown name="swingDirection" options={["left", "right"]} />
			</Section>
			<Section title="Materials">TBD</Section>
			<Navigation label="Door" backward="/step-1" forward="/step-3" />
		</>
	);
}
