import { folder, useControls } from "leva";
import { useAppStore } from "@/stores/appStore";
import CONSTANTS, { TextureOption } from "@/constants";

export const useLeva = () => {
	const update = useAppStore((store) => store.update);
	const updateSauna = useAppStore((store) => store.updateSauna);

	const generalControls = useControls({
		wireframe: false,
		showWalls: {
			value: true,
			onChange: (value: boolean, _, options) => options.initial || update({ showWalls: value }),
		},
	});

	const lightControls = useControls(
		"Lights",
		{
			environmentIntensity: {
				value: 0.15,
				min: 0,
				max: 1,
			},
			pointLightIntensity: {
				value: 0.5,
				min: 0,
				max: 1,
			},
			ambientIntensity: {
				value: 0.5,
				min: 0,
				max: 1,
			},
			backRestIntensity: {
				value: 1,
				min: 0,
				max: 10,
			},
			lightColor: "wheat",
			backRestColor: "wheat",
		},
		{ collapsed: true }
	);

	useControls(
		"Sauna",
		{
			saunaWidth: {
				value: CONSTANTS.SAUNA.DEFAULT_WIDTH,
				min: CONSTANTS.SAUNA.MIN_WIDTH,
				max: CONSTANTS.SAUNA.MAX_WIDTH,
				step: CONSTANTS.SAUNA.WIDTH_STEP_SIZE,
				onChange: (width: number, _, options) => options.initial || updateSauna({ width }),
			},
			saunaHeight: {
				value: CONSTANTS.SAUNA.DEFAULT_HEIGHT,
				min: CONSTANTS.SAUNA.MIN_HEIGHT,
				max: CONSTANTS.SAUNA.MAX_HEIGHT,
				step: CONSTANTS.SAUNA.HEIGHT_STEP_SIZE,
				onChange: (height: number, _, options) => options.initial || updateSauna({ height }),
			},
			saunaDepth: {
				value: CONSTANTS.SAUNA.DEFAULT_DEPTH,
				min: CONSTANTS.SAUNA.MIN_DEPTH,
				max: CONSTANTS.SAUNA.MAX_DEPTH,
				step: CONSTANTS.SAUNA.DEPTH_STEP_SIZE,
				onChange: (depth: number, _, options) => options.initial || updateSauna({ depth }),
			},
		},
		{ collapsed: true }
	);

	const doorControls = useControls(
		"Door",
		{
			showDoor: true,
			swingDirection: {
				options: ["left", "right"],
				onChange: (option, _, options) => options.initial || update({ swingDirection: option as "left" | "right" }),
			},
			doorSegments: {
				value: 3,
				min: 1,
				max: 5,
				step: 1,
			},
			shader: folder(
				{
					doorColor: "white",
					doorReflectivity: {
						value: 0.9,
						min: 0,
						max: 1,
						step: 0.05,
					},
					doorOpacity: {
						value: 0.2,
						min: 0,
						max: 1,
						step: 0.05,
					},
					doorRoughness: {
						value: 0.05,
						min: 0,
						max: 1,
						step: 0.05,
					},
				},
				{ collapsed: true }
			),
		},
		{ collapsed: true }
	);

	const floorControls = useControls(
		"Floor",
		{
			floorThickness: {
				value: 0.02,
				min: 0.01,
				max: 0.04,
				step: 0.005,
			},
			shader: folder(
				{
					floorTexture: {
						value: TextureOption.DEBUG,
						options: TextureOption,
						onChange: (v: TextureOption, _, options) => options.initial || update({ floorTexture: v }),
					},
					floorTextureRepeat: {
						value: 1,
						min: 0.25,
						max: 2,
					},
					floorTextureRoughness: {
						value: 1,
						min: 0,
						max: 1,
					},
					floorTextureNormalScale: [1, 1],
				},
				{ collapsed: true }
			),
		},
		{ collapsed: true }
	);

	const wallsControls = useControls(
		"Walls",
		{
			wallThickness: {
				value: 0.03,
				min: 0.01,
				max: 0.05,
				step: 0.005,
			},
			shader: folder(
				{
					wallTexture: {
						value: TextureOption.DEBUG,
						options: TextureOption,
						onChange: (v: TextureOption, _, options) => options.initial || update({ innerTexture: v }),
					},
					wallTextureRepeat: {
						value: 1,
						min: 0.5,
						max: 2,
					},
					wallTextureRoughness: {
						value: 0.5,
						min: 0,
						max: 1,
					},
					wallTextureNormalScale: [1, 1],
				},
				{ collapsed: true }
			),
		},
		{ collapsed: true }
	);

	const benchControls = useControls(
		"Bench",
		{
			showSkirt: true,
			benchLevels: {
				value: 2,
				min: 1,
				max: 3,
				step: 1,
			},
			benchDepth: {
				value: 0.6,
				min: 0.4,
				max: 0.8,
				step: 0.05,
			},
			benchHeight: {
				value: 0.45,
				min: 0.35,
				max: 0.55,
			},
			dividerWidth: {
				value: 0.1,
				min: 0,
				max: 0.2,
				step: 0.01,
			},
			plankThickness: {
				value: 0.025,
				min: 0.01,
				max: 0.05,
				step: 0.005,
			},
			seatMaxWidth: {
				value: 1,
				min: 0.5,
				max: 3,
				step: 0.1,
			},
			seatHorizontalCount: {
				value: 3,
				min: 1,
				max: 5,
				step: 1,
			},
			seatVerticalCount: {
				value: 3,
				min: 1,
				max: 5,
				step: 1,
			},
			seatHorizontalSpacing: {
				value: 0.005,
				min: 0,
				max: 0.02,
			},
			seatVerticalSpacing: {
				value: 0.005,
				min: 0,
				max: 0.02,
			},
			backRestCount: {
				value: 3,
				min: 1,
				max: 5,
				step: 1,
			},
			backRestSpacing: {
				value: 0.005,
				min: 0,
				max: 0.02,
			},
			backRestSpacerWidth: {
				value: 0.05,
				min: 0.01,
				max: 0.1,
			},
			backRestSpacerOffset: {
				value: 0.3,
				min: 0.1,
				max: 0.5,
			},
			backRestHeight: {
				value: 0.3,
				min: 0.1,
				max: 0.5,
			},
			backRestOffset: {
				value: 0.15,
				min: 0.05,
				max: 0.4,
			},
			bevelThickness: {
				value: 0.0015,
				min: 0,
				max: 0.01,
			},
			shader: folder(
				{
					benchTexture: {
						value: CONSTANTS.SAUNA.DEFAULT_BENCH_TEXTURE,
						options: TextureOption,
					},
					benchTextureRepeat: {
						value: 1,
						min: 0.25,
						max: 2,
					},
					benchTextureRoughness: {
						value: 0.5,
						min: 0,
						max: 1,
					},
					benchTextureNormalScale: [0.3, 0.3],
					benchTextureRandomness: {
						value: 0.2,
						min: 0,
						max: 0.5,
					},
				},
				{
					collapsed: true,
				}
			),
		},
		{ collapsed: true }
	);

	return {
		...generalControls,
		...lightControls,
		...doorControls,
		...floorControls,
		...wallsControls,
		...benchControls,
	};
};
