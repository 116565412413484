import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";
import CONSTANTS, { TextureOption } from "@/constants";

const isDevMode = import.meta.env.DEV;

export type State = {
	saunaDimensions: {
		width: number;
		height: number;
		depth: number;
	};
	innerTexture: TextureOption;
	outerTexture: TextureOption;
	floorTexture: TextureOption;
	benchTexture: TextureOption;
	showWalls: boolean;
	openDoor: boolean;
	swingDirection: "left" | "right";
	benchLevels: number;
	isDevMode: boolean;
};

type Actions = {
	update: (options: Partial<State>) => void;
	updateSauna: (options: Partial<State["saunaDimensions"]>) => void;
};

export const useAppStore = create<State & Actions>((set) => ({
	saunaDimensions: {
		width: CONSTANTS.SAUNA.DEFAULT_WIDTH,
		height: CONSTANTS.SAUNA.DEFAULT_HEIGHT,
		depth: CONSTANTS.SAUNA.DEFAULT_DEPTH,
	},
	innerTexture: CONSTANTS.SAUNA.DEFAULT_WALL_TEXTURE,
	outerTexture: CONSTANTS.SAUNA.DEFAULT_WALL_TEXTURE,
	floorTexture: CONSTANTS.SAUNA.DEFAULT_FLOOR_TEXTURE,
	benchTexture: CONSTANTS.SAUNA.DEFAULT_BENCH_TEXTURE,
	showWalls: true,
	openDoor: false,
	swingDirection: "right",
	benchLevels: 2,
	isDevMode,
	update: (options) => set((state) => ({ ...state, ...options })),
	updateSauna: (options) => set((state) => ({ ...state, saunaDimensions: { ...state.saunaDimensions, ...options } })),
}));

if (isDevMode) {
	mountStoreDevtool("AppStore", useAppStore);
}
