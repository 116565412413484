import styled from "styled-components";
import { PiDoorLight, PiDoorOpenLight, PiWallLight } from "react-icons/pi";
import { PiRulerLight } from "react-icons/pi";
import { useAppStore } from "@/stores/appStore";

const StyledOverlay = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding: 2rem;
	gap: 1rem;

	button {
		padding: 1rem;
		border: none;
		border-radius: 50%;
		color: rgba(from var(--grey-rock) 0.2);
		background-color: var(--coconut-white);
		box-shadow: 0 0 1rem rgb(from var(--grey-rock) r g b / 0.2);
		cursor: pointer;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	button > svg {
		width: 1rem;
		height: 1rem;
	}
`;

const Overlay = () => {
	const showWalls = useAppStore((store) => store.showWalls);
	const openDoor = useAppStore((store) => store.openDoor);
	const update = useAppStore((store) => store.update);

	return (
		<StyledOverlay>
			<button title={showWalls ? "Hide walls" : "Show walls"} onClick={() => update({ showWalls: !showWalls })}>
				<PiWallLight />
			</button>
			<button title={openDoor ? "Close door" : "Open door"} onClick={() => update({ openDoor: !openDoor })}>
				{openDoor ? <PiDoorLight /> : <PiDoorOpenLight />}
			</button>
			<button title="Show/hide measurements">
				<PiRulerLight />
			</button>
		</StyledOverlay>
	);
};

export default Overlay;
