import { setRepeatTextures, useDeferredTexture } from "@/hooks/useDeferredTexture";
import { useLeva } from "@/hooks/useLeva";
import { useAppStore } from "@/stores/appStore";
import { MeshStandardMaterialProps, type MeshProps } from "@react-three/fiber";
import { FunctionComponent } from "react";

interface FloorProps extends MeshProps {
	width: number;
	depth: number;
	thickness: number;
}

const Floor: FunctionComponent<FloorProps> = ({ width, depth, thickness, ...meshProps }) => {
	const floorTexture = useAppStore((store) => store.floorTexture);
	const {
		wireframe,
		floorTextureRoughness: roughness,
		floorTextureRepeat: repeat,
		floorTextureNormalScale: _normalScale,
	} = useLeva();

	const textures = useDeferredTexture(floorTexture);

	const sharedProps = {
		wireframe,
		roughness,
	} satisfies MeshStandardMaterialProps;

	const defaultProps = {
		color: "grey",
		...sharedProps,
	} satisfies MeshStandardMaterialProps;

	const textureProps = {
		...sharedProps,
		...textures,
	} satisfies MeshStandardMaterialProps;

	setRepeatTextures(textures, floorTexture, width * repeat, depth * repeat);

	return (
		<mesh {...meshProps}>
			<boxGeometry args={[width, thickness, depth]} />
			<meshStandardMaterial attach="material-0" {...defaultProps} />
			<meshStandardMaterial attach="material-1" {...defaultProps} />
			<meshStandardMaterial attach="material-2" {...textureProps} />
			<meshStandardMaterial attach="material-3" {...defaultProps} />
			<meshStandardMaterial attach="material-4" {...defaultProps} />
			<meshStandardMaterial attach="material-5" {...defaultProps} />
		</mesh>
	);
};

export default Floor;
